import { Empty, PageHeader, Select } from "antd";
import clsx from "clsx";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { request } from "../../utils/request";
import cls from "./style.module.scss";

interface IMoney {
  Cur_OfficialRate: number;
  byn: number;
  Cur_Abbreviation: string;
}

export const Charts: FC = () => {
  const [money, setMoney] = useState<IMoney[]>([]);

  useEffect(() => {
    request.get("https://www.nbrb.by/api/exrates/rates?periodicity=0").then((response) => {
      let arr = [
        { country: "usa", mony: "USD" },
        { country: "eur", mony: "EUR" },
        { country: "ru", mony: "RUB" },
      ].map((el) => response.data.find((mo: any) => mo.Cur_Abbreviation === el.mony));
      arr = arr.map((el) => ({
        ...el,
        byn: arr[arr.length - 1].Cur_OfficialRate / 100,
      }));
      arr[arr.length - 1].Cur_Abbreviation = "BYN";

      setMoney(arr);
    });
  }, []);

  const [statisticsMonth, setStatisticsMonth] = useState({
    availableYears: [],
    monthlyData: [],
    selectedYear: 2025,
  });
  const [statisticsMonthLoading, setStatisticsMonthLoading] = useState(false);

  const getMonth = async (selectedYear?: number) => {
    try {
      setStatisticsMonthLoading(true);
      const { data } = await request.post("/statistics/month", selectedYear ? { selectedYear } : undefined);
      if (data) {
        setStatisticsMonth(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setStatisticsMonthLoading(false);
    }
  };

  const [statisticsDay, setStatisticsDay] = useState({
    availableYears: [],
    dailyData: [],
    selectedYear: 2025,
  });
  const [statisticsDayLoading, setStatisticsDayLoading] = useState(false);

  const getDay = async (selectedYear?: number) => {
    try {
      setStatisticsDayLoading(true);
      const { data } = await request.post("/statistics/day", selectedYear ? { selectedYear } : undefined);
      if (data) {
        setStatisticsDay(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setStatisticsDayLoading(false);
    }
  };

  const [statisticsCurDay, setStatisticsCurDay] = useState({ dailyData: [], paymentSystemData: [] });
  const [statisticsCurDayLoading, setStatisticsCurDayLoading] = useState(false);

  const getCurDay = async () => {
    try {
      setStatisticsCurDayLoading(true);
      const { data } = await request.post("/statistics/curDay");
      if (data) {
        setStatisticsCurDay(data);
      }
    } catch (e) {
      console.log(e);
    } finally {
      setStatisticsCurDayLoading(false);
    }
  };

  const [paymentSystemData, setPaymentSystemData] = useState([]);

  const getAllTimePaymentSystemStatistics = async () => {
    try {
      const { data } = await request.post("/statistics/getAllTimePaymentSystemStatistics");
      if (data.paymentSystemData) {
        setPaymentSystemData(data.paymentSystemData);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getMonth(2025);
    getDay(2025);
    getCurDay();
    getAllTimePaymentSystemStatistics();
  }, []);

  const refEl = useRef<HTMLDivElement>(null);

  const renderMony = (sum?: any) =>
    sum &&
    money
      .map((el, idx) =>
        (idx === money.length - 1 ? sum * el.byn : (sum * el.byn) / el.Cur_OfficialRate).toLocaleString("ru-RU", {
          style: "currency",
          currency: el.Cur_Abbreviation,
        }),
      )
      .join(", ");

  const CustomTooltip: FC<any> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className={cls.customTooltip}>
          <h2>{label}</h2>
          {!!payload[0].value && (
            <span
              style={{ color: payload[0].color }}
              className={cls.customTooltip__label}
            >{`${payload[0].name} : ${payload[0].value.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(payload[0].value)})`}</span>
          )}
          {!!payload[1]?.value && (
            <span
              style={{ color: payload[1].color }}
              className={cls.customTooltip__label}
            >{`${payload[1].name} : ${payload[1].value.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(payload[1].value)})`}</span>
          )}
          {!!(payload[1]?.value && payload[0]?.value) && (
            <span style={{ color: "#a182ca" }} className={cls.customTooltip__label}>{`Доход : ${(
              payload[1].value - payload[0].value
            ).toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(payload[1].value - payload[0].value)})`}</span>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <PageHeader ghost={false} title="Графики" />
      <div className={cls.wrapperUsers}>
        <h2>Платёжки</h2>
        <div ref={refEl} className={cls.wrapperTable2}>
          <div style={{ height: 270 }}>
            <ResponsiveContainer width="100%" height="100%">
              <RadarChart cx="50%" cy="50%" outerRadius="80%" data={paymentSystemData}>
                <PolarGrid />
                <PolarAngleAxis dataKey="paymentSystem" />
                <Radar name="Mike" dataKey="totalAmount" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
              </RadarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <div className={clsx(cls.wrapperUsers, statisticsCurDayLoading && cls.loading)}>
        <h2>Текущий месяц</h2>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {statisticsCurDay.paymentSystemData.map((el: any) => (
            <span key={el.paymentSystem}>{`${el.paymentSystem} : ${el.totalAmount.toLocaleString("ru-RU", {
              style: "currency",
              currency: "RUB",
            })} (${renderMony(el.totalAmount)})`}</span>
          ))}
        </div>
        {statisticsCurDay.dailyData.length ? (
          <div ref={refEl} className={cls.wrapperTable}>
            <div className={cls.wrapperChart}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={statisticsCurDay.dailyData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <YAxis unit=" ₽" dataKey={(data) => Number(data.totalAmount)} name="Деньги" />
                  <XAxis dataKey={(data) => moment(data.day).format("DD.MM.YY")} />
                  <Tooltip filterNull={false} content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="totalAmount" name="Денюшки" fill="#82ca9d" stackId="1" minPointSize={0} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className={cls.wrapperEmpty}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
              }}
              description={<span>Наполнения отсутствуют</span>}
            ></Empty>
          </div>
        )}
      </div>
      <div className={clsx(cls.wrapperUsers, statisticsDayLoading && cls.loading)}>
        <Select
          value={statisticsDay.selectedYear}
          onChange={getDay}
          options={statisticsDay.availableYears
            .map((key) => ({
              label: key,
              value: Number(key),
            }))
            .concat({ value: null, label: "Все" } as any)}
        />
        {statisticsDay.dailyData.length ? (
          <div ref={refEl} className={cls.wrapperTable}>
            <div className={cls.wrapperChart}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={statisticsDay.dailyData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <YAxis unit=" ₽" dataKey={(data) => Number(data.totalAmount)} name="Деньги" />
                  <XAxis dataKey={(data) => moment(data.day).format("DD.MM.YY")} />
                  <Tooltip filterNull={false} content={<CustomTooltip />} />
                  <Legend />
                  <Bar dataKey="totalCosts" name="Расходы" fill="#ca8282" stackId="1" minPointSize={0} />
                  <Bar dataKey="totalAmount" name="Денюшки" fill="#82ca9d" stackId="1" minPointSize={0} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className={cls.wrapperEmpty}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
              }}
              description={<span>Наполнения отсутствуют</span>}
            ></Empty>
          </div>
        )}
      </div>

      <h2>По месяцам</h2>
      <div className={clsx(cls.wrapperUsers, statisticsMonthLoading && cls.loading)}>
        <Select
          value={statisticsMonth.selectedYear}
          onChange={getMonth}
          options={statisticsMonth.availableYears
            .map((key) => ({
              label: key,
              value: Number(key),
            }))
            .concat({ value: null, label: "Все" } as any)}
        />
        {statisticsMonth.monthlyData.length ? (
          <div ref={refEl} className={cls.wrapperTable}>
            <div className={cls.wrapperChart}>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={statisticsMonth.monthlyData}>
                  <CartesianGrid strokeDasharray="1 1" />
                  <YAxis unit=" ₽" dataKey={(data) => Number(data.totalAmount)} name="Деньги" />
                  <XAxis dataKey={(data) => moment(data.month).format("MM.YYYY")} />
                  <Tooltip filterNull={false} content={<CustomTooltip />} />
                  <Legend />

                  <Bar dataKey="totalCosts" name="Расходы" fill="#ca8282" stackId="1" minPointSize={0} />
                  <Bar dataKey="totalAmount" name="Денюшки" fill="#82ca9d" stackId="2" minPointSize={0} />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ) : (
          <div className={cls.wrapperEmpty}>
            <Empty
              image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
              imageStyle={{
                height: 100,
              }}
              description={<span>Наполнения отсутствуют</span>}
            ></Empty>
          </div>
        )}
      </div>
    </div>
  );
};
