import { Button, Checkbox, Form, Input, notification, PageHeader, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { request } from "../../utils/request";

export const RatingsPage: FC = () => {
  const [page, setPage] = useState<object | null>(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { id, source } = useParams();

  const [form] = useForm();

  const getPage = async (id: number) => {
    setLoading(true);
    const response = await request.post("/ratings/page/get", { id });
    setPage(response.data);
    setLoading(false);
  };

  const save = async (values: any) => {
    values.body = values.body || null;

    if (!values.is_show_in_menu) {
      values.is_show_in_menu = false;
    }

    try {
      if (id) {
        await request.post(`/ratings/page/update`, { ...values, id: Number(id) });
        await getPage(Number(id));
      } else {
        const result = await request.post(`/ratings/page/create`, { ...values, source_id: Number(source) });
        navigate(`/ratings-page/${source}/${result.data.id}`, { replace: true });
      }

      notification.success({ message: "Сохранение прошло успешно" });
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (id) {
      getPage(Number(id));
    }
  }, [id]);

  const onFinish = (values: object) => {
    save(values);
  };

  return (
    <div>
      <PageHeader ghost={false} title={`${id ? "Редактирование" : "Добавление"} страницы`} />

      {loading ? (
        <Spin />
      ) : (
        <Form
          initialValues={
            page || {
              is_show_in_menu: false,
            }
          }
          name="form_item_path"
          layout="vertical"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item name="alias" label="Alias" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="tag" label="Тег" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="title" label="Title" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="heading" label="Заголовок" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="h1" label="Заголовок H1" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="body" label="Содержимое">
            <Input.TextArea rows={20} />
          </Form.Item>
          <Form.Item valuePropName="checked" name="is_show_in_menu">
            <Checkbox>Отобразить в меню</Checkbox>
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" key="add" loading={loading} type="primary" size="middle">
              Сохранить
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};
