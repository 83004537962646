import { CheckOutlined, DeleteOutlined, WomanOutlined } from "@ant-design/icons";
import { Button, Image, PageHeader, Space, Switch, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import axios from "axios";
import moment from "moment/moment";
import React, { FC, useEffect, useState } from "react";

import { IsUnique } from "../../components/is-unique";
import { isPlatform } from "../../utils/isPlatform";
import { request } from "../../utils/request";
import cls from "./style.module.scss";

interface IConfig {
  photo_moderation_enabled: boolean;
}

export const PhotoModeration: FC = () => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [config, setConfig] = useState<IConfig>({
    photo_moderation_enabled: false,
  });

  const getConfig = async () => {
    setLoading(true);
    const response = await request.get("/administrator/config");
    setConfig(response.data);
    setLoading(false);
  };

  const changeConfig = async (config: IConfig): Promise<void> => {
    setLoading(true);
    const response = await request.post("/administrator/config", config);
    setConfig(response.data);
    setLoading(false);
  };

  const getPhotos = async () => {
    const response = await request.post("/profile-photos/moderation/photos");
    setPhotos(response.data);
  };

  const confirm = async (id: number, confirmed: boolean, sexy = false, url: string) => {
    await request.post("/profile-photos/moderation/moderate", {
      id,
      confirmed,
      private: sexy,
    });

    if (confirmed && url) {
      axios
        .post("https://adult-ai.amorus.ru/image/train", {
          imageUrl: [`${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${url}`],
          bool: [sexy],
        })
        .then(console.log)
        .catch(console.error);
    }

    await getPhotos();
  };

  useEffect(() => {
    getConfig();
    getPhotos();
  }, []);

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      width: 100,
      title: "ID профиля",
      dataIndex: "profileId",
      key: "profileId",
      render: (_, { profileId }) => {
        return (
          <a target="_blank" href={`https://cp.amorus.ru/profile/${profileId}`} rel="noreferrer">
            {profileId}
          </a>
        );
      },
    },
    {
      title: "Уникален",
      dataIndex: "profileId",
      render: (id) => <IsUnique profileId={id} />,
    },
    {
      width: 80,
      title: "Фотография",
      dataIndex: "image",
      key: "image",
      render: (_, { image }) => {
        return <Image width={160} src={`${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${image}`} />;
      },
    },
    {
      title: "Пол",
      dataIndex: "sex",
      key: "sex",
      render: (_, { profile: { sex } }) => {
        return sex === "male" ? "Мущына" : "Бэйбачка";
      },
    },
    {
      title: "Имя",
      dataIndex: "name",
      key: "name",
      render: (_, { profile: { name } }) => {
        return name;
      },
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => {
        return moment(createdAt).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      width: 200,
      title: "Управление",
      key: "action",
      render: (_, { id, image }) => (
        <Space>
          <Button
            onClick={() => confirm(id, true, false, image)}
            loading={loading}
            icon={<CheckOutlined />}
            type="primary"
            size="small"
          >
            Одобрить
          </Button>
          <Button
            onClick={() => confirm(id, true, true, image)}
            loading={loading}
            icon={<WomanOutlined />}
            type="dashed"
            size="small"
          >
            Приватное
          </Button>
          <Button
            onClick={() => confirm(id, false, false, image)}
            loading={loading}
            size="small"
            danger={true}
            icon={<DeleteOutlined />}
          >
            Отклонить
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Модерация фотографий"
        extra={
          <Space>
            <Switch
              loading={loading}
              onChange={(value) => changeConfig({ ...config, photo_moderation_enabled: value })}
              checked={config.photo_moderation_enabled}
            />
            <span>{config.photo_moderation_enabled ? "Включено" : "Выключено"}</span>
          </Space>
        }
      />

      <div className={cls.table}>
        <Table columns={columns} rowKey="id" scroll={{ x: 300 }} pagination={false} dataSource={photos} />
      </div>
      <div className={cls.mobile}>
        {photos.map((el: any) => (
          <div className={cls.mobile__card} key={el.id}>
            <Image width={160} src={`${isPlatform("https://api.amorus.ru", "https://api.lovers.su")}${el.image}`} />
            <div className={cls.mobile__card__info}>
              <span>id</span>
              <span>{el.id}</span>
            </div>
            <div className={cls.mobile__card__info}>
              <span>ID профиля</span>
              <span>
                <a target="_blank" href={`https://cp.amorus.ru/profile/${el.profileId}`} rel="noreferrer">
                  {el.profileId}
                </a>
              </span>
            </div>
            <div className={cls.mobile__card__info}>
              <span>Пол</span>
              <span>{el.profile.sex === "male" ? "Мущына" : "Бэйбачка"}</span>
            </div>
            <div className={cls.mobile__card__info}>
              <span>Имя</span>
              <span>{el.profile.name}</span>
            </div>
            <div className={cls.mobile__card__info}>
              <span>Дата</span>
              <span>{moment(el.createdAt).format("DD.MM.YYYY HH:mm")}</span>
            </div>
            <div className={cls.mobile__card__actions}>
              <Button
                onClick={() => confirm(el.id, true, false, el.image)}
                loading={loading}
                icon={<CheckOutlined />}
                type="primary"
                size="large"
                shape="circle"
              />
              <Button
                onClick={() => confirm(el.id, true, true, el.image)}
                loading={loading}
                icon={<WomanOutlined />}
                type="dashed"
                size="large"
                shape="circle"
              />
              <Button
                onClick={() => confirm(el.id, false, false, el.image)}
                loading={loading}
                danger={true}
                icon={<DeleteOutlined />}
                size="large"
                shape="circle"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
