import { DeleteOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, notification, PageHeader, Popconfirm, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React, { FC, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";

import { Pagination } from "../../components/pagination";
import { Profile } from "../../utils/Profile";
import { request } from "../../utils/request";

export const Chats: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { profileId } = useParams();
  const searchProfileId = searchParams.has("searchProfileId") ? searchParams.get("searchProfileId") : undefined;
  const page = searchParams.has("page") ? Number(searchParams.get("page")) : 1;

  const [chats, setChats] = useState<any>({
    data: [],
    pagination: {
      count: 0,
      limit: 0,
      offset: 0,
    },
  });
  const [loading, setLoading] = useState<boolean>(true);

  const getChats = async () => {
    const response = await request.post("/administrator/chat/list", {
      profileId,
      searchProfileId,
      offset: String(50 * (page - 1)),
    });

    setChats(response.data);
    setLoading(false);
  };

  const deleteChats = async (): Promise<void> => {
    try {
      await request.post("/administrator/chat/delete-all-chats", { profileId });
      notification.success({ message: "Ура! Чаты удалены!" });
    } catch (err) {
      console.error(err);
    }

    await getChats();
  };

  useEffect(() => {
    getChats();
  }, [page, searchProfileId]);

  const columns: ColumnsType<any> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 100,
    },
    {
      width: 300,
      title: "Инициатор",
      key: "id",
      render(chat) {
        return (
          <Link to={`/profile/${chat.first_profile.id}`}>
            <Space style={{ minWidth: 200 }}>
              <Avatar size="large" src={Profile.getProfileAvatarUrl(chat.first_profile)} />
              <span>{chat.first_profile.name}</span>
            </Space>
          </Link>
        );
      },
    },
    {
      width: 300,
      title: "Собеседник",
      key: "id",
      render(chat) {
        return (
          <Link to={`/profile/${chat.second_profile.id}`}>
            <Space style={{ minWidth: 200 }}>
              <Avatar size="large" src={Profile.getProfileAvatarUrl(chat.second_profile)} />
              <span>{chat.second_profile.name}</span>
            </Space>
          </Link>
        );
      },
    },
    {
      title: "Сообщений",
      key: "id",
      render(chat) {
        return chat.messages_count;
      },
    },
    {
      width: 100,
      key: "id",
      render(chat) {
        return (
          <Link to={`/chats/messages/${chat.id}`}>
            <Button size="small">Перейти в диалог</Button>
          </Link>
        );
      },
    },
  ];

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Чаты"
        extra={
          <Popconfirm
            title="Точно удалить?"
            onConfirm={deleteChats}
            cancelText="Нет, я передумал"
            okText="Ну конечно!"
            placement="leftTop"
          >
            <Button icon={<DeleteOutlined />} size="small" danger>
              Удалить все чаты
            </Button>
          </Popconfirm>
        }
      />
      <Input.Search
        defaultValue={searchParams.get("searchProfileId") || ""}
        allowClear={true}
        placeholder="Поиск по ID профиля"
        onChange={(e) => {
          const value = e.target.value;
          if (value) {
            setSearchParams({ searchProfileId: value });
          } else {
            searchParams.delete("searchProfileId");
            setSearchParams(searchParams);
          }
        }}
      />
      <br />
      <br />
      <Table loading={loading} rowKey="id" pagination={false} columns={columns} dataSource={chats.data} />
      <Pagination
        count={chats.pagination.count}
        limit={chats.pagination.limit}
        page={searchParams.has("page") ? Number(searchParams.get("page")) : 1}
      />
    </div>
  );
};
