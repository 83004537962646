import { DeleteOutlined } from "@ant-design/icons";
import { Button, PageHeader, Popconfirm, Space, Table, Typography } from "antd";
import { ColumnsType } from "antd/lib/table";
import moment from "moment";
import React, { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { request } from "../../utils/request";

export const RatingsPages: FC = () => {
  const [pages, setPages] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { source } = useParams();

  const getPages = async () => {
    const response = await request.post("/ratings/pages/get", { source_id: source });
    setPages(response.data);
    setLoading(false);
  };

  const deletePage = async (id: number) => {
    try {
      setLoading(true);
      await request.post("ratings/page/delete", { id });
      await getPages();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPages();
  }, []);

  const columns: ColumnsType<any> = [
    {
      width: 50,
      title: "id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Заголовок",
      dataIndex: "heading",
      key: "heading",
    },
    {
      title: "Тег",
      dataIndex: "tag",
      key: "tag",
      render: (tag) => {
        return tag || "-";
      },
    },
    {
      title: "Алиас",
      dataIndex: "alias",
      key: "alias",
      render: (_, { alias, source }) => {
        return (
          <a target="_blank" href={`https://${source.host}/${alias}`} rel="noreferrer">
            /{alias}
          </a>
        );
      },
    },
    {
      title: "Содержимое",
      dataIndex: "body",
      key: "body",
      render: (body: string | null) => {
        return body ? (
          <Typography.Text type="success">Заполнено</Typography.Text>
        ) : (
          <Typography.Text type="danger">Не заполнено</Typography.Text>
        );
      },
    },
    {
      title: "Отображается в меню",
      dataIndex: "is_show_in_menu",
      key: "is_show_in_menu",
      render: (is_show_in_menu: boolean | null) => {
        return is_show_in_menu ? (
          <Typography.Text type="success">Да</Typography.Text>
        ) : (
          <Typography.Text type="danger">Нет</Typography.Text>
        );
      },
    },
    {
      title: "Дата",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (_, { createdAt }) => {
        return moment(createdAt).format("DD.MM.YYYY HH:mm");
      },
    },
    {
      title: "Ресурс",
      key: "id",
      render: (_, { source }) => {
        return source.host;
      },
    },
    {
      width: 200,
      title: "Управление",
      key: "action",
      render: (_, { id }) => (
        <Space>
          <Button href={`/ratings-page/${source}/${id}`} target="_blank" loading={loading} size="small">
            Редактировать
          </Button>
          <Popconfirm
            okType="danger"
            placement="leftTop"
            title="Точно отменить?"
            onConfirm={() => deletePage(id)}
            okText="Да"
            cancelText="Нет"
          >
            <Button loading={loading} size="small" danger={true} icon={<DeleteOutlined />}>
              Удалить
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <PageHeader
        ghost={false}
        title="Страницы рейтинга"
        extra={[
          <Button
            href={`/ratings-page/${source}`}
            key="add"
            loading={loading}
            type="primary"
            size="middle"
            target="_blanc"
          >
            Добавить
          </Button>,
        ]}
      />
      <Table
        loading={loading}
        rowKey="id"
        scroll={{ x: 300 }}
        pagination={false}
        columns={columns}
        dataSource={pages}
      />
    </div>
  );
};
